<template>
  <div>
    <div
      class="d-flex justify-end"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      flat
      tile
    >
      <div style="width: 80%"></div>
      <v-select
        class="pa-2"
        outlined
        filled
        dense
        v-model="dateRange"
        @input="(dateRange) => dateRangeChanged(dateRange)"
        :items="[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date',
        ]"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Custom Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <div style="padding: 10px">
            <table>
              <tr>
                <td>
                  <h4>Total Purchase</h4>
                  <h6>excluding tax & discount</h6>
                </td>
                <td>{{ getData.total_purchase }} {{ currency }}</td>
              </tr>

              <tr>
                <td>
                  <h4>Total Expenses</h4>
                </td>
                <td>{{ getData.total_expenses }} {{ currency }}</td>
              </tr>

              <tr>
                <td>
                  <h4>Total Sale Discount</h4>
                </td>
                <td>{{ getData.total_sale_discount }} {{ currency }}</td>
              </tr>
              <tr>
                <td>
                  <h4>Loss from Inventory Adjustment</h4>
                </td>
                <td>{{ getData.loss_from_inventory_adjustment }} {{ currency }}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <div style="padding: 10px">
            <table>
              <tr>
                <td>
                  <h4>Total Purchase Discount</h4>
                </td>
                <td>{{ getData.total_purchase_discount }} {{ currency }}</td>
              </tr>
              <tr>
                <td>
                  <h4>Total Sales</h4>
                  <h6>excluding tax & discount</h6>
                </td>
                <td>{{ getData.total_sales }} {{ currency }}</td>
              </tr>

              <tr>
                <td>
                  <h4>Total Tax</h4>
                </td>
                <td>{{ getData.total_tax }} {{ currency }}</td>
              </tr>
              <tr>
                <td>
                  <h4>Total Services</h4>
                </td>
                <td>{{ getData.total_services }} {{ currency }}</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-card style="padding: 20px">
      <h3>
        Gross Profit:
        {{ grossProfit }}
        {{ currency }}
      </h3>
      <h6>
        (Total Sale - Total Sale Discount) - ((Total Purchase - Total Purchase Discount) +
        Loss from Inventory Adjustment)
      </h6>
      <br />
      <h3>
        Net Profit:
        {{
          grossProfit +
          getData.total_sale_shipping_charge +
          getData.total_services -
          (getData.total_expenses + getData.total_tax)
        }}
        {{ currency }}
      </h3>
      <h6>(Gross Profit + Total Services) - (Total Expenses + Total Taxes)</h6>
    </v-card>
    <br />
    <v-card>
      <v-tabs v-model="tab" fixed-tabs background-color="indigo" dark>
        <v-tab> Profit by Sales Agent </v-tab>
        <v-tab> Profit by Product </v-tab>
        <v-tab> Profit by Magazine </v-tab>
        <v-tab> Profit by Origin </v-tab>
      </v-tabs>
      <div style="padding: 10px">
        <v-data-table
          v-show="tab == 0"
          :headers="[
            { text: 'Agent Name', value: 'name' },
            { text: 'Gross Profit', value: 'profit' },
          ]"
          :items="getData.profit_by_sales_agent"
          :items-per-page="10"
        >
          <template v-slot:item.profit="{ item }">
            {{ item.profit }} {{ currency }}
          </template>
        </v-data-table>

        <v-data-table
          v-show="tab == 1"
          :headers="[
            { text: 'Product Name', value: 'name' },
            { text: 'Gross Profit', value: 'profit' },
          ]"
          :items="getData.profit_by_product"
          :items-per-page="10"
        >
          <template v-slot:item.profit="{ item }">
            {{ item.profit }} {{ currency }}
          </template>
        </v-data-table>
        <v-data-table
          v-show="tab == 2"
          :headers="[
            { text: 'Magazine Name', value: 'name' },
            { text: 'Gross Profit', value: 'profit' },
          ]"
          :items="getData.profit_by_magazine"
          :items-per-page="10"
        >
          <template v-slot:item.profit="{ item }">
            {{ item.profit }} {{ currency }}
          </template>
        </v-data-table>
        <v-data-table
          v-show="tab == 3"
          :headers="[
            { text: 'Origin Name', value: 'origin_name' },
            { text: 'Gross Profit', value: 'profit' },
          ]"
          :items="getData.profit_by_origin"
          :items-per-page="10"
        >
          <template v-slot:item.profit="{ item }">
            {{ item.profit }} {{ currency }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";
import CheckinInfo from "./CheckinInfo.vue";
import {
  deleteExpense,
  deleteOrder,
  getCheckins,
  getCurrency,
  getExpenses,
  getProfitLoss,
} from "@/ApiManager";
export default {
  computed: {
    getData() {
      console.log(this.data);
      return this.data;
    },
    grossProfit() {
      return (
        this.getData.total_sales -
        this.getData.total_sale_discount -
        (this.getData.total_purchase -
          this.getData.total_purchase_discount +
          this.getData.loss_from_inventory_adjustment)
      );
    },
  },

  data() {
    return {
      dateRange: "This Month",
      date: null,
      menu: null,

      data: {},
      currency: "",
      tab: null,
    };
  },
  components: {},
  methods: {
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      console.log(dateRange);
      getProfitLoss(dateRange).then((response) => {
        this.data = response;
        this.$store.state.loading = false;
      });
    },
  },
  created() {
    this.$store.state.loading = true;
    getCurrency().then((response) => {
      this.currency = response.currency;
    });

    getProfitLoss(this.dateRange).then((response) => {
      this.data = response;
      this.$store.state.loading = false;
    });
  },
};
</script>

<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: var(--v-primaryLighter-base);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 20px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 16 px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>
